import { Grid, makeStyles } from "@material-ui/core"
import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { useDebounce } from "use-debounce"

import { CloseIcon } from "~/components/icons/CloseIcon"
import { SearchIcon } from "~/components/icons/SearchIcon"

const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    width: "100%",
    height: theme.spacing(7),
    borderRadius: theme.spacing(1),
    boxShadow: "0 1px 4px 0 rgba(157, 157, 157, 0.5)",
    backgroundColor: "#fff",
    position: "relative",
    marginBottom: theme.spacing(4),
  },
  searchIcon: {
    marginLeft: theme.spacing(1.25),
    marginRight: theme.spacing(0.5),
    color: "#959595",
  },
  closeIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    position: "absolute",
    top: theme.spacing(2.25),
    right: theme.spacing(1.25),
    cursor: "pointer",
    color: "#959595",
  },
  searchInput: {
    width: "85%",
    height: "inherit",
    border: 0,
    fontSize: "17px",
    lineHeight: "22px",
    outline: "none",
    borderRadius: theme.spacing(1),
  },
}))

const updateAfter = 500

export const CustomSearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  const classes = useStyles()
  const [search, setSearch] = React.useState(`${currentRefinement}`)
  const [debounceSearchValue] = useDebounce(search, updateAfter)

  React.useEffect(() => {
    refine(debounceSearchValue)
  }, [debounceSearchValue])

  const handleClearSearch = () => {
    setSearch("")
  }

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      className={classes.searchWrapper}
      spacing={0}
    >
      <SearchIcon className={classes.searchIcon} />
      <input
        type="text"
        name="search"
        placeholder="Find something delicious…"
        className={classes.searchInput}
        value={search}
        onChange={(event) => {
          setSearch(event.currentTarget.value)
        }}
      />
      {search.length > 0 && <CloseIcon onClick={handleClearSearch} className={classes.closeIcon} />}
    </Grid>
  )
})
