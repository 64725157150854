import { Grid, makeStyles } from "@material-ui/core"
import { default as NavLink } from "next/link"
import React from "react"
import Carousel from "react-multi-carousel"

import { CollectionRecipeBlock } from "~/components/collections/CollectionRecipeBlock"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    position: "relative",
    width: "calc(100% + 32px)",
    marginLeft: "-16px",
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.2",
    letterSpacing: "0.38px",
    color: "#2b2b2b",
  },
  seeAll: {
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.31",
    letterSpacing: "-0.32px",
    textAlign: "right",
    color: "#646464",
    textDecoration: "none",
    "&:hover": {
      color: "#2b2b2b",
    },
  },
}))

const responsive = {
  tablet: {
    breakpoint: { max: 5000, min: 464 },
    items: 2.14,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.6,
    slidesToSlide: 2,
  },
}

export const CollectionsMobileList = ({ collection }) => {
  const classes = useStyles({ photoUrl: collection.photoUrl, shadow: false })

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.header}>
        <Grid item xs={9}>
          <div className={classes.title}>{collection.title}</div>
        </Grid>
        <Grid item container direction="row" justify="flex-end" alignItems="center" xs={3}>
          <NavLink href={"/collection/[collectionId]"} as={`/collection/${collection.id}`} passHref={true}>
            <a className={classes.seeAll}>See All</a>
          </NavLink>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <style jsx global>{`
          .list-container > .react-multi-carousel-track {
            -ms-touch-action: pan-y;
            touch-action: pan-y;
          }

          .list-container.react-multi-carousel-list > ul > li:first-child {
            padding-left: 16px;
          }

          .list-container.react-multi-carousel-list > ul > li {
            padding: 5px;
          }

          .list-container.react-multi-carousel-list > ul > li:last-child {
            padding-right: 16px;
          }

          .list-container.react-multi-carousel-list > ul > li > a {
            box-shadow: 0 1px 4px 0 rgba(157, 157, 157, 0.5);
          }
        `}</style>
        <Carousel
          deviceType={"mobile"}
          swipeable={true}
          draggable={true}
          responsive={responsive}
          showDots={false}
          arrows={false}
          ssr={true}
          infinite={false}
          autoPlay={false}
          keyBoardControl={true}
          minimumTouchDrag={1}
          containerClass={"list-container"}
        >
          {collection.recipes.length ? (
            collection.recipes
              .filter((recipe) => recipe?.objectID)
              .map((recipe, index) => <CollectionRecipeBlock key={index} recipe={recipe} />)
          ) : (
            <Grid item xs={12}>
              not found
            </Grid>
          )}
        </Carousel>
      </div>
    </>
  )
}
