import { Grid, makeStyles } from "@material-ui/core"
import { default as NavLink } from "next/link"
import React from "react"

import { CollectionRecipeBlock } from "~/components/collections/CollectionRecipeBlock"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(7),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.2",
    letterSpacing: "0.38px",
    color: "#2b2b2b",
  },
  seeAll: {
    fontSize: "16px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.31",
    letterSpacing: "-0.32px",
    textAlign: "right",
    color: "#646464",
    textDecoration: "none",
    "&:hover": {
      color: "#2b2b2b",
    },
  },
}))

export const CollectionsDesktopList = ({ collection }) => {
  const classes = useStyles({ photoUrl: collection.photoUrl, shadow: false })

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.header}>
        <Grid item xs={9}>
          <div className={classes.title}>{collection.title}</div>
        </Grid>
        <Grid item container direction="row" justify="flex-end" alignItems="center" xs={3}>
          <NavLink href={"/collection/[collectionId]"} as={`/collection/${collection.id}`} passHref={true}>
            <a className={classes.seeAll}>See All</a>
          </NavLink>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        {collection.recipes.length ? (
          collection.recipes
            .filter((recipe) => recipe?.objectID)
            .slice(0, 4)
            .map((recipe, index) => (
              <Grid key={index} item xs={6} sm={6} md={3} lg={3}>
                <CollectionRecipeBlock recipe={recipe} />
              </Grid>
            ))
        ) : (
          <Grid item xs={12}>
            not found
          </Grid>
        )}
      </Grid>
    </div>
  )
}
