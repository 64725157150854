import { Grid, makeStyles } from "@material-ui/core"
import { uniqBy } from "lodash"
import { default as NavLink } from "next/link"
import React from "react"

import { UsersPreview } from "~/components/common/UsersPreview"

type TProps = {
  photoUrl: string
  margin: boolean
}

const useStyles = makeStyles((theme) => ({
  root: ({ margin }: TProps) => ({
    flexGrow: 1,
    height: "200px",
    boxShadow: "0 1px 4px 0 rgba(157, 157, 157, 0.5)",
    borderRadius: theme.spacing(1),
    display: "block",
    marginBottom: margin ? theme.spacing(4) : "auto",
  }),
  image: ({ photoUrl }: TProps) => ({
    width: "100%",
    height: "200px",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundImage: `url(${photoUrl})`,
    borderRadius: theme.spacing(1),
    position: "relative",
  }),
  shadow: {
    width: "100%",
    height: "131px",
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000 77%)",
    position: "absolute",
    bottom: 0,
    opacity: 0.55,
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
  content: {
    width: "100%",
    // backgroundColor: "#ff0000",
    padding: theme.spacing(2),
    position: "absolute",
    bottom: 0,
  },
  length: {
    fontSize: "12px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "normal",
    color: "#ffffff",
  },
  title: {
    width: "auto",
    minWidth: "100%",
    maxWidth: "100px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    zIndex: 10,
    position: "relative",
    display: "block",
    fontSize: "22px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.27",
    letterSpacing: "0.35px",
    color: "#ffffff",
    textAlign: "left",
    marginBottom: theme.spacing(1),
  },
  readMore: {
    width: "160px",
    height: "36px",
    display: "inline-block",
    borderRadius: "27.6px",
    backgroundColor: "#fa5b3a",
    fontSize: "16px",
    lineHeight: "1.31",
    letterSpacing: "-0.32px",
    color: "#ffffff",
    textDecoration: "none",
    padding: "8px 46px",
    marginTop: theme.spacing(3),
  },
}))

export const CollectionsMobileSlide = ({ collection, margin = true }) => {
  const modifiedPhotoUrl = collection.photoUrl.startsWith(
    "https://res.cloudinary.com/anova-applied-electronics/image/upload/",
  )
    ? collection.photoUrl.replace("/upload/", "/upload/w_552,h_200,c_fill,f_auto,q_auto,dpr_auto,/")
    : collection.photoUrl

  const classes = useStyles({
    photoUrl: modifiedPhotoUrl,
    margin,
  })

  const users = collection.recipes.map((recipe) => ({
    ...recipe?.userProfile,
    userId: recipe?.userId,
  }))
  const uniqueUsers = uniqBy(users, (user) => user.userId)

  return (
    <NavLink href={`/collection/[collectionId]`} as={`/collection/${collection.id}`} passHref={true}>
      <a className={classes.root}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12}>
            <div className={classes.image}>
              <div className={classes.shadow}></div>
              <div className={classes.content}>
                <div className={classes.length}>{collection.recipes.length} Combi Steam Oven Recipes</div>
                <div className={classes.title}>{collection.title}</div>
                <UsersPreview users={uniqueUsers} color={"#ffffff"} />
              </div>
            </div>
          </Grid>
        </Grid>
      </a>
    </NavLink>
  )
}
