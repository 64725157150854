import { SvgIcon } from "@material-ui/core"

export const SearchIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <defs>
        <filter id="prefix__a" width="101.9%" height="137.5%" x="-1%" y="-18.8%" filterUnits="objectBoundingBox">
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.615687274 0 0 0 0 0.615687274 0 0 0 0 0.615687274 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g fillRule="evenodd" filter="url(#prefix__a)" transform="translate(-10 -16)">
        <path d="M25.5 30h-.79l-.28-.27c.98-1.14 1.57-2.62 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5S13 21.91 13 25.5s2.91 6.5 6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L30.49 35l-4.99-5zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5z" />
      </g>
    </SvgIcon>
  )
}
