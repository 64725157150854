import { Grid, makeStyles } from "@material-ui/core"
import React from "react"
import Carousel from "react-multi-carousel"

import { CollectionsDesktopSlide } from "~/components/collections/CollectionsDesktopSlide"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3.5),
    paddingBottom: theme.spacing(3.5),
    position: "relative",
    width: "calc(100% + 10px)",
    right: theme.spacing(0.625),
  },
  slide: {
    margin: theme.spacing(0.625),
  },
  activeDots: {
    width: "12px",
    height: "12px",
    backgroundColor: "#2b2b2b",
    borderRadius: "50%",
    marginLeft: "4px",
    marginRight: "4px",
    cursor: "pointer",
  },
  inactiveDots: {
    width: "12px",
    height: "12px",
    backgroundColor: "#fff",
    border: "solid 2px #959595",
    borderRadius: "50%",
    marginLeft: "4px",
    marginRight: "4px",
    cursor: "pointer",
  },
}))

const CustomDot = (props) => {
  const { active, activeClassName: activeclassname, inactiveClassName: inactiveclassname } = props

  return <div className={active ? `${activeclassname}` : `${inactiveclassname}`} />
}

const responsive = {
  desktop: {
    breakpoint: { max: 9999, min: 1280 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1279, min: 960 },
    items: 1,
  },
}

export const CollectionDesktopSlider = ({ collections }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Carousel
        deviceType={"desktop"}
        swipeable={true}
        draggable={true}
        responsive={responsive}
        renderDotsOutside={true}
        showDots={true}
        customDot={<CustomDot activeClassName={classes.activeDots} inactiveClassName={classes.inactiveDots} />}
        ssr={true}
        infinite={true}
        autoPlay={false}
        keyBoardControl={true}
      >
        {collections.length ? (
          collections
            .filter((collection) => collection?.id)
            .map((collection, index) => (
              <div key={index} className={classes.slide}>
                <CollectionsDesktopSlide collection={collection} margin={false} />
              </div>
            ))
        ) : (
          <Grid item xs={12}>
            not found
          </Grid>
        )}
      </Carousel>
    </div>
  )
}
