import { Grid, Hidden, makeStyles } from "@material-ui/core"

import { CollectionDesktopShopifyCTA } from "./CollectionDesktopShopifyCTA"
import { CollectionDesktopSlider } from "./CollectionDesktopSlider"
import { CollectionMobileShopifyCTA } from "./CollectionMobileShopifyCTA"
import { CollectionMobileSlider } from "./CollectionMobileSlider"
import { CollectionsDesktopList } from "./CollectionsDesktopList"
import { CollectionsDesktopSlide } from "./CollectionsDesktopSlide"
import { CollectionsMobileList } from "./CollectionsMobileList"
import { CollectionsMobileSlide } from "./CollectionsMobileSlide"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    flex: "1 0 auto",
    margin: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.2",
    letterSpacing: "0.38px",
    color: "#2b2b2b",
  },
}))

const CollectionComponents = ({ collections }) => (
  <>
    {Array.isArray(collections) &&
      collections.map((collection, index) => {
        if (index === 3) {
          return (
            <div key={index}>
              <Hidden initialWidth={"md"} only={["xs", "sm"]}>
                <CollectionDesktopShopifyCTA />
              </Hidden>
              <Hidden initialWidth={"sm"} only={["md", "lg", "xl"]}>
                <CollectionMobileShopifyCTA />
              </Hidden>
            </div>
          )
        }

        if (index === 4) {
          // Render the existing component at the 3rd position
          if (collection.type === "oven-recipe-collection-gallery" && collection.collections.length > 1) {
            return (
              <div key={index}>
                <Hidden initialWidth={"sm"} only={["xs", "sm"]}>
                  <CollectionDesktopSlider collections={collection.collections} />
                </Hidden>
                <Hidden initialWidth={"sm"} only={["md", "lg", "xl"]}>
                  <CollectionMobileSlider collections={collection.collections} />
                </Hidden>
              </div>
            )
          }

          if (collection.type === "oven-recipe-collection-gallery" && collection.collections.length === 1) {
            return (
              <div key={index}>
                <Hidden initialWidth={"md"} only={["xs", "sm"]}>
                  <CollectionsDesktopSlide collection={collection.collections[0]} />
                </Hidden>
                <Hidden initialWidth={"sm"} only={["md", "lg", "xl"]}>
                  <CollectionsMobileSlide collection={collection.collections[0]} />
                </Hidden>
              </div>
            )
          }

          if (collection.type === "oven-recipe-collection" && collection.collection.photoUrl) {
            return (
              <div key={index}>
                <Hidden initialWidth={"md"} only={["xs", "sm"]}>
                  <CollectionsDesktopSlide collection={collection.collection} />
                </Hidden>
                <Hidden initialWidth={"sm"} only={["md", "lg", "xl"]}>
                  <CollectionsMobileSlide collection={collection.collection} />
                </Hidden>
              </div>
            )
          }

          if (collection.type === "oven-recipe-collection" && collection.collection.photoUrl === null) {
            return (
              <div key={index}>
                <Hidden initialWidth={"md"} only={["xs", "sm"]}>
                  <CollectionsDesktopList collection={collection.collection} />
                </Hidden>
                <Hidden initialWidth={"sm"} only={["md", "lg", "xl"]}>
                  <CollectionsMobileList collection={collection.collection} />
                </Hidden>
              </div>
            )
          }
        }

        if (collection.type === "oven-recipe-collection-gallery" && collection.collections.length > 1) {
          return (
            <div key={index}>
              <Hidden initialWidth={"sm"} only={["xs", "sm"]}>
                <CollectionDesktopSlider collections={collection.collections} />
              </Hidden>
              <Hidden initialWidth={"sm"} only={["md", "lg", "xl"]}>
                <CollectionMobileSlider collections={collection.collections} />
              </Hidden>
            </div>
          )
        }

        if (collection.type === "oven-recipe-collection-gallery" && collection.collections.length === 1) {
          return (
            <div key={index}>
              <Hidden initialWidth={"md"} only={["xs", "sm"]}>
                <CollectionsDesktopSlide collection={collection.collections[0]} />
              </Hidden>

              <Hidden initialWidth={"sm"} only={["md", "lg", "xl"]}>
                <CollectionsMobileSlide collection={collection.collections[0]} />
              </Hidden>
            </div>
          )
        }

        if (collection.type === "oven-recipe-collection" && collection.collection.photoUrl) {
          return (
            <div key={index}>
              <Hidden initialWidth={"md"} only={["xs", "sm"]}>
                <CollectionsDesktopSlide collection={collection.collection} />
              </Hidden>
              <Hidden initialWidth={"sm"} only={["md", "lg", "xl"]}>
                <CollectionsMobileSlide collection={collection.collection} />
              </Hidden>
            </div>
          )
        }

        if (collection.type === "oven-recipe-collection" && collection.collection.photoUrl === null) {
          return (
            <div key={index}>
              <Hidden initialWidth={"md"} only={["xs", "sm"]}>
                <CollectionsDesktopList collection={collection.collection} />
              </Hidden>
              <Hidden initialWidth={"sm"} only={["md", "lg", "xl"]}>
                <CollectionsMobileList collection={collection.collection} />
              </Hidden>
            </div>
          )
        }

        return null
      })}
  </>
)

export const Collections = ({ collections }) => {
  const classes = useStyles()

  return (
    <>
      <CollectionComponents collections={collections} />
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.header}>
        <Grid item xs={12}>
          <div className={classes.title}>Combi Steam Oven Recipes</div>
        </Grid>
      </Grid>
    </>
  )
}
