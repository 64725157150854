import React from "react"
import { Configure, InstantSearch } from "react-instantsearch-dom"

import { Collections } from "~/components/collections"
import { CustomHits } from "~/components/search/CustomHits"
import { CustomPagination } from "~/components/search/CustomPagination"
import { CustomSearchBox } from "~/components/search/CustomSearchBox"

export const Search = (props) => {
  return (
    <>
      <InstantSearch
        searchClient={props.searchClient}
        resultsState={props.resultsState}
        onSearchStateChange={props.onSearchStateChange}
        searchState={props.searchState}
        createURL={props.createURL}
        indexName={props.indexName}
        onSearchParameters={props.onSearchParameters}
      >
        <Configure hitsPerPage={11} />
        <CustomSearchBox autoFocus showLoadingIndicator />
        {!("query" in props.searchState || "page" in props.searchState) && (
          <Collections collections={props.collections} />
        )}
        <CustomHits />
        <CustomPagination />
      </InstantSearch>
    </>
  )
}
