import { Avatar, makeStyles } from "@material-ui/core"
import React from "react"

import { getWebpResizeImage } from "~/helpers"

type TProps = {
  color: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  userAvatar: {
    "& div": {
      float: "left",
    },
    "& div:nth-child(1)": {
      position: "relative",
      zIndex: 30,
    },
    "& div:nth-child(2)": {
      position: "relative",
      zIndex: 20,
      right: "2px",
    },
    "& div:nth-child(3)": {
      position: "relative",
      zIndex: 10,
      right: "4px",
    },
  },
  small: {
    width: theme.spacing(2.25),
    height: theme.spacing(2.25),
    border: "solid 1px #ffffff",
  },
  users: ({ color }: TProps) => ({
    fontSize: "11px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.18",
    letterSpacing: "0.06px",
    color: `${color}`,
    position: "relative",
    top: theme.spacing(0.5),
    left: theme.spacing(0.5),
  }),
}))

export const UsersPreview = ({ users, color = "#646464" }) => {
  const classes = useStyles({ color })

  return (
    <>
      <div className={classes.userAvatar}>
        {users
          .slice(0, 3)
          .map((user, index) =>
            user?.photoUrl ? (
              <Avatar
                key={index}
                alt={`${user?.name}`}
                src={getWebpResizeImage(`${user?.photoUrl}`)}
                className={classes.small}
              />
            ) : (
              <Avatar key={index} className={classes.small} />
            ),
          )}
        {users.length > 3 && <div className={classes.users}>+ {users.length - 3} more</div>}
      </div>
    </>
  )
}
