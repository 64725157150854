import { Grid, makeStyles, Paper } from "@material-ui/core"
import { uniqBy } from "lodash"
import { default as NavLink } from "next/link"
import React from "react"

import { UsersPreview } from "~/components/common/UsersPreview"
import { getWebpResizeImage } from "~/helpers"

type TProps = {
  margin: boolean
}

const useStyles = makeStyles((theme) => ({
  root: ({ margin }: TProps) => ({
    flexGrow: 1,
    height: "300px",
    boxShadow: "0 1px 4px 0 rgba(157, 157, 157, 0.5)",
    borderRadius: theme.spacing(1),
    marginBottom: margin ? theme.spacing(7) : "auto",
  }),
  image: {
    width: "100%",
    height: "300px",
    objectFit: "cover",
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
  },
  content: {
    height: "300px",
  },
  length: {
    fontSize: "16px",
    lineHeight: "1.31",
    letterSpacing: "-0.32px",
    color: "#646464",
  },
  title: {
    fontSize: "28px",
    fontWeight: "bold",
    letterSpacing: "0.36px",
    color: "#2b2b2b",
    marginBottom: theme.spacing(1),
    textAlign: "center",
    padding: "0 24px",
  },
  readMore: {
    width: "180px",
    textAlign: "center",
    height: "36px",
    display: "inline-block",
    borderRadius: "27.6px",
    backgroundColor: "#fa5b3a",
    fontSize: "16px",
    lineHeight: "1.31",
    letterSpacing: "-0.32px",
    color: "#ffffff",
    textDecoration: "none",
    padding: "8px 46px",
    marginTop: theme.spacing(3),
    transition: "all .5s",
    "&:hover": {
      backgroundColor: "#2b2b2b",
    },
  },
}))

export const CollectionsDesktopSlide = ({ collection, margin = true }) => {
  const classes = useStyles({
    margin,
  })
  const users = collection.recipes.map((recipe) => ({
    ...recipe?.userProfile,
    userId: recipe?.userId,
  }))
  const uniqueUsers = uniqBy(users, (user) => user.userId)

  return (
    <Paper className={classes.root}>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={6}>
          <img
            src={getWebpResizeImage(collection.photoUrl, 600, 300)}
            alt={`${collection.title}`}
            className={classes.image}
          />
        </Grid>
        <Grid item container direction="column" justify="center" alignItems="center" xs={6} className={classes.content}>
          <Grid item>
            <div className={classes.length}>{collection.recipes.length} Combi Steam Oven Recipes</div>
          </Grid>
          <Grid item>
            <div className={classes.title}>{collection.title}</div>
          </Grid>
          <Grid item>
            <div>
              <UsersPreview users={uniqueUsers} />
            </div>
          </Grid>
          <Grid item>
            <NavLink href={"/collection/[collectionId]"} as={`/collection/${collection.id}`} passHref={true}>
              <a className={classes.readMore}>Read now</a>
            </NavLink>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
