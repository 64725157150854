import { Container } from "@material-ui/core"
import algoliasearch from "algoliasearch/lite"
import { isEqual } from "lodash"
import absoluteUrl from "next-absolute-url"
import Head from "next/head"
import { withRouter } from "next/router"
import qs from "qs"
import React from "react"
import { findResultsState } from "react-instantsearch-dom/server"

import { Layout } from "~/components/common/Layout"
import { Search } from "~/components/search"

const searchClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APP_ID, process.env.NEXT_PUBLIC_ALGOLIA_API_KEY)

const createURL = (state) => {
  return `?${qs.stringify(state)}`
}

const pathToSearchState = (path) => {
  return path.includes("?") ? qs.parse(path.substring(path.indexOf("?") + 1)) : {}
}

const searchStateToURL = (searchState) => {
  if (searchState.query || searchState.page !== 1) {
    return `${window.location.pathname}?${qs.stringify(searchState)}`
  }

  return "/"
}

const DEFAULT_PROPS = {
  searchClient,
  indexName: "oven_recipes",
}

const Index = ({ collections, resultsState, searchState, router, origin }) => {
  const SITELINK_JSON_LD = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": `${origin}/`,
    "potentialAction": {
      "@type": "SearchAction",
      "target": `${origin}/?query={search_term_string}&page=1`,
      "query-input": "required name=search_term_string",
    },
  }
  const [search, setSearch] = React.useState(searchState)
  const [lastRouter, setLastRouter] = React.useState(router)
  const onSearchStateChange = (searchState) => {
    delete searchState.configure
    const href = searchStateToURL(searchState)
    const queryString = searchState.query || ""
    const resultCount = resultsState.rawResults[0].nbHits || null
    const fullURL = `${origin}${href}`

    router.push(href, href, {
      shallow: true,
    })

    if (window.gtag) {
      window.gtag("event", "view_recipe_search_results", {
        send_to: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_4_ID,
        property: "Oven Recipe Site",
        search_string: queryString,
        result_count: resultCount,
        page_url: fullURL,
      })
    }

    setSearch({ searchState })
  }

  React.useEffect(() => {
    if (!isEqual(lastRouter, router)) {
      setSearch(pathToSearchState(router.asPath))
      setLastRouter(router)
    }
  }, [router])

  return (
    <Layout searchTitle={searchState.query}>
      <Container fixed>
        <Head>
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(SITELINK_JSON_LD) }} />
        </Head>
        <Search
          {...DEFAULT_PROPS}
          searchState={search}
          resultsState={resultsState}
          onSearchStateChange={onSearchStateChange}
          createURL={createURL}
          collections={collections}
        />
      </Container>
    </Layout>
  )
}

Index.getInitialProps = async ({ req, asPath }) => {
  const { origin } = absoluteUrl(req)

  const searchState = pathToSearchState(asPath)
  const resultsState = await findResultsState(Search, {
    ...DEFAULT_PROPS,
    searchState,
  })

  const fetchUrl = `${origin}/api/collections`
  const res = await fetch(fetchUrl)
  const collections = await res.json()

  return {
    collections: collections.results,
    resultsState,
    searchState,
    origin,
  }
}

Index.displayName = "Index"

export default withRouter(Index)
