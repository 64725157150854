import { Grid, makeStyles, Paper } from "@material-ui/core"
import { default as NavLink } from "next/link"
import React from "react"

import { CommentIcon } from "~/components/icons/CommentIcon"
import { RatingIcon } from "~/components/icons/RatingIcon"
import { getWebpResizeImage } from "~/helpers"

const useStyles = makeStyles((theme) => ({
  recipesUrl: {
    textDecoration: "none",
    "&:hover": {
      "& > div": { boxShadow: "0 1px 12px 0 rgba(157, 157, 157, 0.5)" },
    },
  },
  recipesItem: {
    borderRadius: theme.spacing(1),
    boxShadow: "0 1px 4px 0 rgba(157, 157, 157, 0.5)",
    textDecoration: "none",
    transition: "all .5s ease",
  },
  recipesImage: {
    width: "100%",
    height: "158px",
    objectFit: "cover",
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    backgroundColor: "#f9f9f9",
  },
  recipesTitle: {
    fontSize: "17px",
    lineHeight: "22px",
    letterSpacing: "-0.41px",
    color: "#2b2b2b",
    fontWeight: "bold",
    width: "auto",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  recipesMeta: {
    minHeight: theme.spacing(10.375),
    padding: theme.spacing(1, 1, 1.25, 1),
    overflow: "hidden",
  },
  recipesUserMeta: {
    display: "flex",
    marginTop: theme.spacing(0.5),
    alignItems: "center",
    justifyContent: "center",
  },
  recipesUserPhotoUrl: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    objectFit: "cover",
    borderRadius: "50%",
    backgroundColor: "#f9f9f9",
    alignSelf: "center",
  },
  recipesUserName: {
    marginLeft: theme.spacing(1),
    color: "#2b2b2b",
    fontSize: "13px",
    lineHeight: "18px",
    letterSpacing: "-0.08px",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  divider: {
    width: "auto",
    height: "1px",
    margin: theme.spacing(0, 1),
    backgroundColor: "#e8e8e8",
  },
  recipesIconWrapper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5, 1, 1.5, 1),
  },
  ratingIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: "50%",
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1))",
    backgroundColor: "#ffa700",
    marginRight: theme.spacing(0.75),
  },
  ratingIcon: {
    display: "inline-flex",
    alignItems: "center",
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },
  commentIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: "50%",
    backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1))",
    backgroundColor: "#a6ca2d",
    marginRight: theme.spacing(0.75),
    marginLeft: theme.spacing(2),
  },
  commentIcon: {
    display: "inline-flex",
    alignItems: "center",
    width: theme.spacing(1.25),
    height: theme.spacing(1.25),
    fill: "white",
  },
  metaValue: {
    fontSize: "0.8125rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.38",
    letterSpacing: "-0.08px",
    color: "#646464",
  },
}))

export const CollectionRecipeBlock = ({ recipe }) => {
  const classes = useStyles()

  return (
    <NavLink href={"/recipe/[recipeId]"} as={`/recipe/${recipe.objectID}`} passHref={true}>
      <a className={classes.recipesUrl}>
        <Paper className={classes.recipesItem} elevation={0}>
          <img
            src={getWebpResizeImage(recipe.coverPhotoUrl, 400, 400)}
            alt={`${recipe.title}`}
            className={classes.recipesImage}
          />
          <Grid
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.recipesMeta}
          >
            <div className={classes.recipesTitle}>{recipe.title}</div>
            <div className={classes.recipesUserMeta}>
              <img
                src={getWebpResizeImage(recipe.userProfile.photoUrl, 16, 16, ["r_max"])}
                alt={`${recipe.userProfile.name}`}
                className={classes.recipesUserPhotoUrl}
              />
              <div className={classes.recipesUserName}>{recipe.userProfile.name}</div>
            </div>
          </Grid>
          <div className={classes.divider}></div>
          <div className={classes.recipesIconWrapper}>
            <div className={classes.ratingIconWrapper}>
              <RatingIcon className={classes.ratingIcon} />
            </div>
            <div className={classes.metaValue}>{recipe.averageRating}</div>
            <div className={classes.commentIconWrapper}>
              <CommentIcon className={classes.commentIcon} />
            </div>
            <div className={classes.metaValue}>{recipe.numberOfComments}</div>
          </div>
        </Paper>
      </a>
    </NavLink>
  )
}
