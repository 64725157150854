import { Grid, makeStyles } from "@material-ui/core"
import React from "react"
import Carousel from "react-multi-carousel"

import { CollectionsMobileSlide } from "~/components/collections/CollectionsMobileSlide"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    position: "relative",
    width: "calc(100% + 32px)",
    marginLeft: "-16px",
  },
  activeDots: {
    width: "12px",
    height: "12px",
    backgroundColor: "#2b2b2b",
    borderRadius: "50%",
    marginLeft: "4px",
    marginRight: "4px",
    cursor: "pointer",
  },
  inactiveDots: {
    width: "12px",
    height: "12px",
    backgroundColor: "#fff",
    border: "solid 2px #959595",
    borderRadius: "50%",
    marginLeft: "4px",
    marginRight: "4px",
    cursor: "pointer",
  },
}))

const responsive = {
  mobile: {
    breakpoint: { max: 959, min: 0 },
    items: 1.07,
  },
}

export const CollectionMobileSlider = ({ collections }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <style jsx global>{`
        .slider-container > .react-multi-carousel-track {
          -ms-touch-action: pan-y;
          touch-action: pan-y;
        }

        .slider-container.react-multi-carousel-list > ul > li:first-child {
          padding-right: 10px;
          padding-left: 16px;
        }

        .slider-container.react-multi-carousel-list > ul > li {
          padding-top: 5px;
          padding-right: 10px;
          padding-bottom: 5px;
        }

        .slider-container.react-multi-carousel-list > ul > li:last-child {
          padding-right: 16px;
        }

        .slider-container.react-multi-carousel-list > ul > li > a {
          box-shadow: 0 1px 4px 0 rgba(157, 157, 157, 0.5);
        }
      `}</style>
      <Carousel
        deviceType={"mobile"}
        swipeable={true}
        draggable={true}
        responsive={responsive}
        showDots={false}
        arrows={false}
        ssr={true}
        infinite={false}
        autoPlay={false}
        keyBoardControl={true}
        minimumTouchDrag={1}
        containerClass={"slider-container"}
      >
        {collections.length ? (
          collections
            .filter((collection) => collection?.id)
            .map((collection, index) => <CollectionsMobileSlide key={index} collection={collection} margin={false} />)
        ) : (
          <Grid item xs={12}>
            not found
          </Grid>
        )}
      </Carousel>
    </div>
  )
}
